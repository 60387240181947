.navbarCont {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbarContInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  /* max-width: 90%; */
  /* max-width: 95%; */
  max-width: 97%;
  padding: 10px 0;
}

@media (max-width: 900px) {
  .navbarHamburger {
    margin-right: 10px;
    height: 2rem;
    width: 2rem;
    margin-bottom: 2rem;
  }
}

.navbarHamburger svg {
  height: 2rem;
  width: 2rem;
}

.dashboardName {
  font-size: 18px;
  font-weight: 600;
}

.navContentLeft {
  display: flex;
  align-items: center;
}

.inputSearchField {
  margin-right: 30px;
}

.input {
  color: #d4d4d4;
  font-size: 12px;
  font-weight: 500;
  background-color: #f4f4f4;
  border: none;
}

.navContentLeftIcons {
  margin-right: 40px;
  font-size: 20px;
  color: #686b6f;
  cursor: pointer;
}

.profileCont {
  display: flex;
  align-items: center;
}

.profileCont img {
  max-width: 40px;
  max-height: 40px;
  margin: 10px 15px 0 0;
}

.profileDetails {
  margin-top: 10px;
}

.profileName {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 5px;
}

.profileRole {
  font-size: 10px;
  font-size: 600;
  color: #27156f;
  margin: 0;
}

.poName {
  font-weight: bold;
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
}

.poweredBy p {
  margin-right: 20px;
}

.poweredBy .itf {
  max-width: 50px;
  max-height: 50px;
}

.poweredBy .neca {
  max-width: 120px;
  max-height: 50px;
}

.tsdp {
  font-size: 12px;
  font-weight: 600;
  color: #555555;
}

.sidebarLogo {
  margin: 0px auto 12px auto;
  max-width: 70%;
  /* z-index: 1000; */
}

.sidebarLogo img {
  max-width: 150px;
  max-height: 150px;
}

/* Responsiveness */
@media (max-width: 425px) {
  .dashboardName {
    font-size: 14px;
  }

  .profileDetails {
    display: none;
  }
}

@media (max-width: 375px) {
  .navContentLeftIcons {
    margin-right: 20px;
  }
}
