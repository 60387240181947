.mainDashboardContent {
    background-color: #fafafa;
    height: 100vh;
  }
  
  .tradeAreaCardCont {
    display: grid;
    /* grid-template-columns: repeat(5, 2fr); */
    grid-template-columns: repeat(2, 2fr);
    margin: auto;
    max-width: 98%;
  }
  
  .breadCrumb {
    color: #c4c4c4;
  }
  
  .ActionContainer {
    padding: 1rem 0rem;
  }
  
  .actionBtns {
    border-radius: 15px;
    border: none;
    font-weight: 600;
    letter-spacing: 0.1rem;
    font-size: 13px;
  }
  
  .actionBtns:hover {
    opacity: 0.85;
  }
  
  .actionBtn1,
  .actionBtn1:hover {
    background: #eaeaea;
    color: #686b6f;
  }
  
  .actionBtn2,
  .actionBtn2:hover {
    background: #eaeaea;
    color: #686b6f;
  }
  
  .actionBtn3,
  .actionBtn3:hover {
    background: #27156f;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px 14px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    font-size: 10px;
    min-width: 123px;
  }
  
  /* SEARCH FORM SECTION */
  
  .hasSearch {
    position: relative;
  }
  
  .SearchBox {
    padding: 0.5rem 2rem;
    outline: none;
    border-radius: 10px;
    border: 1.8px solid #f6f6f6;
    color: #555555;
    background-color: #f6f6f6;
    font-weight: 500;
    font-size: 14px;
    width: 250px;
  }
  
  .SearchBox:focus {
    font-weight: normal;
    border: 1.8px solid #f6f6f6;
    background-color: #f6f6f6;
  }
  
  .formControlSearchIcon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1rem;
    height: 2.3rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    left: 12px;
    color: #686b6f;
  }
  
  /* TICKET TABLE SECTION */
  
  .TicketCheckBox > * {
    border: 1px solid #686b6f;
  }
  
  .TableHeadRow {
    font-size: 12px;
    border-bottom: 0.6px solid #555555;
    color: #686b6f;
  }
  
  .TableHeadRow th {
    font-weight: 900;
    letter-spacing: 0.1rem;
  }
  
  .TableBodyRow {
    font-size: 13px;
    padding: 15px;
    color: #686b6f;
  }
  
  .TableBodyRow td {
    border-bottom: 0.6px solid #555555;
    padding: 1.5rem 0.5rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
  }
  
  .SortArrow {
    font-size: 1.2rem;
    color: #686b6f;
  }
  
  .statusBadge {
    border: none;
    border-radius: 15px;
    letter-spacing: 0.1rem;
    font-size: 10px;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    background: #fdeebe;
    width: 90px;
    max-width: 95px;
  }
  
  .unemployed {
    color: #ffb800;
    background: #fdeebe;
  }
  
  .Cancelled {
    color: #ed4848;
    background: #f7cdcd;
  }
  
  .employed {
    color: #42c157;
    background: #caf7d1;
  }
  
  /* ACTION ICONS */
  
  .ViewIcon {
    color: #42c157;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .EditIcon {
    color: #3192e4;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .TrashIcon {
    color: #ed4848;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .thAction {
    display: none;
  }
  .trAction {
    text-align: center;
  }
  
  .paginationBtn {
    width: 80%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    color: #876bf7;
    margin-top: 10px;
  }
  
  /* .paginationBtn:hover{
    color: white;
  } */
  
  .paginationBtn a {
    padding: 5px 10px;
    margin: 3px;
    border-radius: 5px;
    border: 1px solid #27156f;
    color: #27156f;
    text-decoration: none;
    cursor: pointer;
  }
  
  .paginationBtn a:hover {
    color: white;
    background-color: #27156f;
  }
  
  .paginationActive{
    color: white;
  }
  
  .paginationActive a {
    color: white;
    background-color: #27156f;
  }
  
  .paginationDisabled {
    display: none;
    
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .tradeAreaCardCont {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
.mainDashboardContent {
  background-color: #fafafa;
  height: 100vh;
}

.tradeAreaCardCont {
  display: grid;
  /* grid-template-columns: repeat(5, 2fr); */
  grid-template-columns: repeat(2, 2fr);
  margin: auto;
  max-width: 98%;
}

.breadCrumb {
  color: #c4c4c4;
}

.ActionContainer {
  padding: 1rem 0rem;
}

.actionBtns {
  border-radius: 15px;
  border: none;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 13px;
}

.actionBtns:hover {
  opacity: 0.85;
}

.actionBtn1,
.actionBtn1:hover {
  background: #eaeaea;
  color: #686b6f;
}

.actionBtn2,
.actionBtn2:hover {
  background: #eaeaea;
  color: #686b6f;
}

.actionBtn3,
.actionBtn3:hover {
  background: #27156f;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 10px 14px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 10px;
  min-width: 123px;
}

/* SEARCH FORM SECTION */

.hasSearch {
  position: relative;
}

.SearchBox {
  padding: 0.5rem 2rem;
  outline: none;
  border-radius: 10px;
  border: 1.8px solid #f6f6f6;
  color: #555555;
  background-color: #f6f6f6;
  font-weight: 500;
  font-size: 14px;
  width: 250px;
}

.SearchBox:focus {
  font-weight: normal;
  border: 1.8px solid #f6f6f6;
  background-color: #f6f6f6;
}

.formControlSearchIcon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1rem;
  height: 2.3rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  left: 12px;
  color: #686b6f;
}

/* TICKET TABLE SECTION */

.TicketCheckBox > * {
  border: 1px solid #686b6f;
}

.TableHeadRow {
  font-size: 12px;
  border-bottom: 0.6px solid #555555;
  color: #686b6f;
}

.TableHeadRow th {
  font-weight: 900;
  letter-spacing: 0.1rem;
}

.TableBodyRow {
  font-size: 13px;
  padding: 15px;
  color: #686b6f;
}

.TableBodyRow td {
  border-bottom: 0.6px solid #555555;
  padding: 1.5rem 0.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.SortArrow {
  font-size: 1.2rem;
  color: #686b6f;
}

.statusBadge {
  border: none;
  border-radius: 15px;
  letter-spacing: 0.1rem;
  font-size: 10px;
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  background: #fdeebe;
  width: 90px;
  max-width: 95px;
}

.unemployed {
  color: #ffb800;
  background: #fdeebe;
}

.Cancelled {
  color: #ed4848;
  background: #f7cdcd;
}

.employed {
  color: #42c157;
  background: #caf7d1;
}

/* ACTION ICONS */

.ViewIcon {
  color: #42c157;
  font-size: 1.2rem;
  cursor: pointer;
}

.EditIcon {
  color: #3192e4;
  font-size: 1.2rem;
  cursor: pointer;
}

.TrashIcon {
  color: #ed4848;
  font-size: 1.2rem;
  cursor: pointer;
}

.thAction {
  display: none;
}
.trAction {
  text-align: center;
}

.paginationBtn {
  width: 80%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  color: #876bf7;
  margin-top: 10px;
}

/* .paginationBtn:hover{
  color: white;
} */

.paginationBtn a {
  padding: 5px 10px;
  margin: 3px;
  border-radius: 5px;
  border: 1px solid #27156f;
  color: #27156f;
  text-decoration: none;
  cursor: pointer;
}

.paginationBtn a:hover {
  color: white;
  background-color: #27156f;
}

.paginationActive{
  color: white;
}

.paginationActive a {
  color: white;
  background-color: #27156f;
}

.paginationDisabled {
  display: none;
  
}

/* Responsiveness */
@media (max-width: 768px) {
  .tradeAreaCardCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
  