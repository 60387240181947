.adminDashboardCont {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

@media (max-width: 1200px) {
  .sidebarMobileShadow {
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    pointer-events: none;
  }
}

.sidebarContOuter {
  width: 20%;
  height: 100%;
  position: fixed;
  /* z-index: 2; */
  top: 0;
  left: 0;
  pointer-events: fill;
}

.mainContent {
  width: 80%;
  margin-left: 20%;
}

.mainDashboardContent {
  background-color: #fafafa;
  height: 100vh;
}

.active {
  color: #8569f5;
}

/* Responsiveness */
@media (max-width: 1223px) {
  .mainContent {
    width: 100%;
    margin-left: 0;
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .sidebarContOuter {
    width: 30%;
  }
}

@media (max-width: 425px) {
  .sidebarContOuter {
    width: 60%;
  }
}
