.csv {
  border: none;
  text-decoration: none;
  background: #eaeaea;
  color: #686b6f;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 13px;
}
.actionBtns {
  border-radius: 15px;
  border: none;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 13px;
}

.actionBtns:hover {
  opacity: 0.85;
}

.actionBtn1,
.actionBtn1:hover,
.actionBtn1:focus {
  background: #eaeaea;
  color: #686b6f;
  padding: 6px 12px;
}
