.mainDashboardContent {
  background-color: #fafafa;
  height: 100vh;
}

.breadCrumb {
  color: #c4c4c4;
}

/* CARD CONTAINER */

.cardContainer {
  margin-bottom: 3rem;
}

/* BENEFICIARY CARD */
.card {
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1.5rem;
}

.cardHeading {
  font-size: 16px;
  color: #686b6f;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.1em;
}

.datePickerInput,
.datePickerInput:hover,
.datePickerInput:focus {
  max-width: 145px;
  background: #f4f6f9;
  border-radius: 10px;
  border: none;
  color: #686b6f;
  font-size: 12px;
  font-weight: 700;
  outline: none;
}

.currentDay {
  color: #0077dc;
  font-size: 15px;
  padding: 0 1.5rem;
  font-weight: 600;
}

/* STAT CARD */
.card2 {
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
}

.statData {
  font-size: 14px;
  color: #686b6f;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.089rem;
}

.statInfo {
  font-size: 22px;
  color: #ed4848;
  font-weight: bolder;
}

.statInfo2 {
  color: #0077dc;
}

/* RECENT ACTIVITIES */
.recentActContainer:first-of-type {
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 5rem;
}

.recentActContainer {
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 10rem;
}

.paddingCard {
  padding: 1.5rem;
}

.activitiesCard {
  border-top: 0.6px solid #c4c4c4;
  padding: 1.2rem 1.5rem;
}

.activitiesCard:last-child {
  border-bottom: 0.6px solid #c4c4c4;
}

.activityTitle {
  font-size: 15px;
  font-weight: 600;
  color: #686b6f;
  letter-spacing: 0.1em;
}

.activityTime {
  font-size: 14px;
}

.poweredBy p {
  margin-right: 20px;
  margin-bottom: 15px;
}

.poweredBy img {
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 8px;
}

/* 
.adminDashboardCont {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.sidebarContOuter {
  width: 20%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

.mainContent {
  width: 80%;
  margin-left: 20%;
}

.mainDashboardContent {
  background-color: #fafafa;
  height: 100vh;
}

.active {
  color: #8569f5;
} */
/* 
.breadCrumbItem {
  color: #c4c4c4;
} */

/* Responsiveness */
/* @media (max-width: 768px) {
  .sidebarContOuter {
    display: none;
  }

  .mainContent {
    width: 100%;
    margin-left: 0;
  }
} */

.profileImageContainer {
  position: relative;
  top: 0;
  left: 0;
}

.profileImageWrapper {
  width: 150px;
  height: 150px;
}

.profileImageWrapper img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileImageOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
