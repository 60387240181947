.instruction {
  font-size: 16px;
}

.actionBtn {
  font-size: 13px;
  min-width: 50px;
  border: none;
  border-radius: 5px;
  width: 22px;
  padding: 13px;
  margin: 0 0.5rem;
}

.confirm,
.confirm:hover,
.confirm:focus {
  background: #ed4848;
  color: #ffffff;
}

.reject,
.reject:hover,
.reject:focus {
  background: #0077dc;
  color: #ffffff;
}
