.instruction {
  font-size: 16px;
}

.updateBtn,
.updateBtn:hover {
  background: #27156f;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 10px 14px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 10px;
  min-width: 123px;
}

.errorMessage {
  font-size: 14px;
}
