.informationContainer {
  /* height: 15vh; */
  width: 95%;
  display: flex;
  margin: 15px auto;
}

.noOfTrade {
  flex: 1;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

p {
  margin-bottom: 0rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
}

.value {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .informationContainer {
    flex-direction: column;
    /* height: 20vh; */
  }
}

@media (max-width: 425px) {
  .title {
    font-size: 12px;
  }
}
