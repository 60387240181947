.actionBtn:hover {
    opacity: 0.8;
  }
  
  .saveBtn,
  .saveBtn:hover,
  .saveBtn:focus {
    background: #27156f;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px 14px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    font-size: 10px;
    min-width: 123px;
  }
  
  /* FORM */
  .formInput {
    /* border-radius: 12px; */
    /* background: #f4f6f9; */
    /* text-transform: capitalize; */
    height: 45px;
    /* border: none; */
    outline: none;
    /* font-weight: bold; */
    font-size: 15px;
    /* padding-left: 21px; */
    letter-spacing: 0.1em;
  }
  
  .formInput:focus {
    /* background: #f4f6f9; */
    /* color: #c4c4c4; */
  }
  
  .instruction {
    font-size: 16px;
  }
  