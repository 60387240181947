.sidebarLogo {
  margin: 12px auto;
  max-width: 70%;
}

.sidebarLogo img {
  max-width: 150px;
  max-height: 150px;
}

.sidebarContent {
  background-color: #27156f;
  color: white;
  height: 100vh;
}

.sidebarContentInner {
  margin: auto;
  max-width: 70%;
}

.sidebarLinksHeader {
  padding-top: 30px;
  color: #959595;
  font-weight: 600;
  font-size: 14px;
}

.sidebarLinks {
  font-size: 14px;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
  position: relative;
  cursor: pointer;
  font-weight: 500;
}

.sidebarLinks:hover {
  color: #8569f5;
}

.sidebarLinks p:nth-of-type(1) {
  font-size: 18px;
  margin-right: 15px;
}

.sidebarLinks p:nth-of-type(2) {
  padding-top: 5px;
}

.sidebarLinks p:nth-of-type(3) {
  position: absolute;
  right: 0;
  font-size: 18px;
}

.tradeAreasInner {
  margin: 0 0 15px 0;
  padding-left: 35px;
  color: white;
  font-size: 12px;
}

.logoutBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 50px;
}

.logoutBtn p:nth-of-type(1) {
  font-size: 18px;
  margin-right: 15px;
}

.logoutBtn p:nth-of-type(2) {
  font-size: 14px;
  padding-top: 5px;
}

.logoutBtn:hover {
  color: #8569f5;
}

/* Reponsiveness */
@media (max-width: 1024px) {
  .logoutBtn {
    margin-top: 10px;
  }
}
